<template>
  <main class="relative">
    <button
      v-show="currentInput < 4"
      @click="$router.push({ name: 'Register' })"
      style="
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #656c7d;
      "
      class="absolute hidden md:block bg-none focus:outline-none border-none right-12 mt-7 z-50"
    >
      Don't have an account? <span style="color: #ff4e00">Sign Up</span>
    </button>
    <easiLayout :step="currentInput">
      <div
        class="md:py-10 h-full min-h-screen !bg-white relative flex items-start sm:items-center justify-center"
      >
        <div
          class="w-11/12 sm:w-10/12 mx-auto md:w-10/12 flex flex-col items-center justify-center"
        >
          <easiContainer
            bg="   "
            :isClose="false"
            @close="$router.push('/')"
            class="w-full bg-white md:pt-16 pb-20 h-screen-80 rounded-80px"
          >
            <div class="flex md:h-full flex-col justify-between px-5 md:px-16">
              <div class="w-full space-y-3 flex flex-col items-center">
                <!-- /////Personal Card //////-->

                <!-- <img class="w-16 h-16 mb-4" src="@/assets/img/signupLogo.svg" alt="" /> -->
                <h3 class="head-text">Sign in to Eazipay</h3>
                <span class="sub-head-text text-bodyDark"
                  >Sign in your details to access your account</span
                >

                <form
                  @submit.prevent="submit"
                  ref="form"
                  autocomplete="on"
                  class="mt-4 md:w-1/2 w-full md:mx-auto"
                >
                  <easiTextInput
                    required
                    placeholder="Email address or phone number"
                    label="Email address or phone number"
                    v-model="userInput"
                    :error="errorRules.userInput"
                  >
                    <!-- <div
                    v-if="currentInput === 0"
                    class="text-left justify-start items-center mt-3 gap-2 flex"
                  >
                    <input
                      class="my-2 focus:outline-none"
                      v-model="args.rememberMe"
                      type="checkbox"
                      name="storage-box"
                      id="storage-box"
                    />

                    <div
                      class="relative flex items-center text-gray-500 cursor-pointer hover:text-gray-600"
                      x-data="{ hover: false }"
                    >
                      <label for="storage-box">Remember me?</label>
                    </div>
                  </div> -->
                  </easiTextInput>
                  <easiTextInput
                    required
                    label="Passcode"
                    placeholder="Passcode"
                    :loading="loading"
                    v-model="args.pin"
                    :maxlength="6"
                    :type="showPin ? 'number' : 'password'"
                    class="mt-10"
                    :error="errorRules.pin"
                  >
                    <template #prependIcon>
                      <i
                        v-show="!showPin"
                        @click="showPin = !showPin"
                        class="fas fa-eye text-sm"
                      />
                      <i
                        v-show="showPin"
                        @click="showPin = !showPin"
                        class="fas fa-eye-slash text-sm"
                      />
                    </template>
                    <!-- <div class="text-left">
                    <span class="capitalize text-newGray text-sm ml-2"
                      >Enter Passcode
                    </span>
                    <span
                      class="mb-4 w-fit flex justify-around gap-2 md:gap-4 items-center"
                    >
                      <easiOtp
                        class="w-full sm:w-auto"
                        :type="showPin ? 'password' : 'number'"
                        size="10"
                        :token_length="6"
                        :max-length="1"
                        :error="errorRules.pin"
                        @input="checkPin"
                      />
                      <img
                        v-if="showPin"
                        @click="showPin = !showPin"
                        class="cursor-pointer"
                        src="../../assets/icons/eye-close.svg"
                      />
                      <img
                        v-else
                        @click="showPin = !showPin"
                        class="cursor-pointer"
                        src="../../assets/icons/eye-open.svg"
                      />
                    </span>
                  </div>

                  <div class="mb-4 flex flex-col items-start justify-between">
                    <div class="justify-center items-center ml-2 gap-2 flex">
                      <input
                        class="my-2"
                        v-model="loginAsEmployee"
                        type="checkbox"
                        name="box"
                        id=""
                      />
            

                      <div
                        class="relative flex items-center text-gray-500 cursor-pointer hover:text-gray-600"
                        x-data="{ hover: false }"
                        @mouseenter="showTip = true"
                        @mouseleave="showTip = false"
                      >
                        <p>Login as an employee</p>
                        <div class="relative">
                          <div
                            v-show="showTip"
                            class="absolute bottom-0 inline-block w-64 px-4 py-3 mb-10 -ml-32 text-white bg-primary rounded-lg"
                            x-show="hover"
                            x-transition:enter="transition ease-out duration-300"
                            x-transition:enter-start="opacity-0 transform scale-90"
                            x-transition:enter-end="opacity-100 transform scale-100"
                            x-transition:leave="transition ease-in duration-300"
                            x-transition:leave-start="opacity-100 transform scale-100"
                            x-transition:leave-end="opacity-0 transform scale-90"
                            x-cloak
                          >
                            <span class="inline-block text-xs"
                              >For employees who have been invited as an
                              uploader, auditor or assigned to a role.</span
                            >
                            <span
                              class="absolute bottom-0 right-0 w-5 h-5 -mb-1 transform rotate-45 bg-primary"
                              style="left: 50%"
                            ></span>
                          </div>
                          <svg
                            class="w-4 h-4 ml-1"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>

                    <span
                      @click="$router.push('/reset-password')"
                      class="font-medium self-end text-sm cursor-pointer hover:text-secondary"
                      >Forgot passcode?</span
                    >
                  </div> -->
                  </easiTextInput>
                  <div class="justify-start items-center mt-3 gap-2 flex">
                    <input
                      class="my-2"
                      v-model="loginAsEmployee"
                      type="checkbox"
                      name="box"
                      id=""
                    />

                    <div
                      class="relative flex items-center text-gray-500 cursor-pointer hover:text-gray-600"
                      x-data="{ hover: false }"
                      @mouseenter="showTip = true"
                      @mouseleave="showTip = false"
                    >
                      <p>Login as an employee</p>
                      <div class="relative">
                        <div
                          style="z-index: 999"
                          v-show="showTip"
                          class="absolute bottom-0 inline-block w-64 px-4 py-3 mb-10 -ml-32 text-white bg-primary rounded-lg"
                          x-show="hover"
                          x-transition:enter="transition ease-out duration-300"
                          x-transition:enter-start="opacity-0 transform scale-90"
                          x-transition:enter-end="opacity-100 transform scale-100"
                          x-transition:leave="transition ease-in duration-300"
                          x-transition:leave-start="opacity-100 transform scale-100"
                          x-transition:leave-end="opacity-0 transform scale-90"
                          x-cloak
                        >
                          <span class="inline-block text-xs"
                            >For employees who have been invited as an uploader,
                            auditor or assigned to a role.</span
                          >
                          <span
                            class="absolute bottom-0 right-0 w-5 h-5 -mb-1 transform rotate-45 bg-primary"
                            style="left: 50%"
                          ></span>
                        </div>
                        <svg
                          class="w-4 h-4 ml-1"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-between my-5 items-center">
                    <p
                      style="
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                      "
                    >
                      Remember my sign in details
                    </p>

                    <SwitchInput
                      @switch="switchAsync($event)"
                      :boolValue="args.rememberMe"
                      class="w-12"
                    />
                  </div>

                  <div class="flex mt-12 justify-center">
                    <easiButton type="submit" :loading="loading" size="small"
                      ><span class="w-32">Sign In</span></easiButton
                    >
                  </div>
                </form>
              </div>

              <!-- <div class="text-signinGrey text-base text-center">
              Dont have an account?
              <router-link class="text-signinGrey" to="/register"
                >Sign up</router-link
              >
            </div> -->
              <div
                @click="$router.push('/reset-password')"
                style="
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 20px;
                  margin-bottom: 8px; /* Paragraph spacing */
                  letter-spacing: 0.25px;
                  color: #708f89;
                "
                class="cursor-pointer mt-6 text-signinGrey text-base text-center"
              >
                Forgot your Passcode?
              </div>

              <button
                v-show="currentInput < 4"
                @click="$router.push({ name: 'Register' })"
                style="
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #656c7d;
                "
                class="bg-none focus:outline-none border-none mt-10 md:hidden z-50"
              >
                Don't have an account?
                <span style="color: #ff4e00">Sign Up</span>
              </button>
            </div>
          </easiContainer>
        </div>
      </div>
    </easiLayout>
  </main>
</template>

<script setup>
import { useDataStore } from "../../stores/data.js";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { ref, reactive, onMounted } from "vue";
import { helperFunctions } from "../../composable/helperFunctions";
import SwitchInput from "@/components/global/InputSwitch.vue";

const { processNumber } = helperFunctions;
const { mutate, query } = useDataStore();
const toast = useToast();
const router = useRouter();

const showPin = ref(false);
const loading = ref(false);
const showTip = ref(false);
const loginAsEmployee = ref(false);
let userInput = ref("");
const currentInput = ref(1);

const refreshToken = ref(false);
const args = reactive({
  email: "",
  phoneNumber: "",
  pin: "",
  rememberMe: false, //set to false so user can interact with it intentionally,
});

const switchAsync = async (e) => {
  args.rememberMe = e;
};

const errorRules = reactive({
  userInput: false,
  pin: false,
});

function checkInputType(payload) {
  if (payload.includes("@")) {
    args.email = payload;
    return "email";
  } else {
    args.phoneNumber = payload;
    return "phone";
  }
}

async function queryCompany() {
  try {
    await query({
      endpoint: "FetchCompaniesByAdminId",
      service: "SETTINGS",
      storeKey: "companyDetails",
    });
  } catch (e) {
    console.log(e);
  }
}

function validate() {
  if (userInput.value.trim() == "") {
    errorRules.userInput = "Please fill in your email or phone number";
    return false;
  } else if (args.pin.trim() == "") {
    errorRules.pin = "Please enter a pin";
    return false;
  } else {
    errorRules.email = false;
    errorRules.pin = false;

    return true;
  }
}

function handleNext() {
  if (args.rememberMe) {
    const inputType = checkInputType(userInput.value);
    const loginMethod = inputType === "email" ? "EMAIL" : "PHONE";
    const detailsObj = { user: userInput.value, loginMethod };
    window.localStorage.setItem("ezpuser", JSON.stringify(detailsObj));
  } else {
    window.localStorage.removeItem("ezpuser");
  }
  errorRules.userInput = "";
  // currentInput.value++;
}
function checkPin(otp_input) {
  args.pin = otp_input;
}

function sixChar(inp) {
  return inp.slice(0, 6);
}
async function submit() {
  const endpoint = loginAsEmployee.value
    ? "EmployeeLogin"
    : "CompanyAdminLogin";
  handleNext();
  if (validate()) {
    const inputType = checkInputType(userInput.value);
    const loginMethod = inputType === "email" ? "EMAIL" : "PHONE";
    const channel = inputType === "email" ? "EMAIL" : "SMS";
    const value =
      inputType === "email" ? args.email : processNumber(args.phoneNumber);

    let payload = {
      loginWith: loginMethod,
      pin: sixChar(String(args.pin)),
      rememberMe: args.rememberMe,
    };
    if (inputType === "email") {
      payload.email = args.email.trim().toLocaleLowerCase();
    } else {
      payload.phoneNumber = processNumber(args.phoneNumber);
    }

    loading.value = true;

    try {
      let res = await mutate({
        endpoint,
        data: { input: payload },
        service: "AUTH",
      });

      console.log("Logggging in");

      if (res.success) {
        if (loginAsEmployee.value) {
          const extractedTitles = res.data.employeeRoles.flatMap((item) =>
            item.privileges.map((privilege) => privilege.title)
          );

          window.localStorage.setItem("token", res.data.token);
          window.localStorage.setItem("refreshToken", res.data.refreshToken);
          window.localStorage.setItem("companyId", res.data.user.companyId);
          if (
            res.data &&
            res.data.employeeRoles &&
            res.data.employeeRoles.length
          ) {
            window.localStorage.setItem(
              "role",
              res.data.employeeRoles[0].title
            );
          }
          window.localStorage.setItem("accountType", res.data.accountType);
          useDataStore().$patch({
            isLoggedIn: true,
          });
          if (extractedTitles.includes("Api")) {
            // if your'e a developer redirect to the api
            router.push({ name: "APIDashboard" });
          } else {
            router.push({ name: "Dashboard" });
          }
        } else {
          window.localStorage.setItem("token", res.token);
          window.localStorage.setItem("refreshToken", res.refreshToken);
          window.localStorage.setItem("privilege", res.privileges);
          window.localStorage.setItem("companyId", res.data.company._id);
          window.localStorage.setItem("accountType", res.accountType);
          window.localStorage.setItem(
            "userDetails",
            JSON.stringify(res.data.admin)
          );
          window.localStorage.setItem(
            "registrationType",
            res.data.company.registrationType
          );
          // await queryCompany();

          useDataStore().$patch({
            userPrivileges: res.privileges,
            isLoggedIn: true,
          });
          router.push({ name: "Dashboard" });
        }

        // toast.success("Login successful");
      } else {
        errorRules.pin = res.message;

        if (res.message === "User account has not been confirmed") {
          // toast.error(res.message);

          window.localStorage.setItem(
            "confirmUserDetails",
            JSON.stringify({ channel, value })
          );

          await sendOTP(channel, value);
          router.push("/register?display=otp-verify");
        }
      }
    } catch (e) {
      loading.value = false;
      toast.error(e.message);
      // log(e);
    } finally {
      loading.value = false;
    }
  }
}

async function sendOTP(channel, value) {
  //validate form field
  const payload = {
    channel,
    value,
    type: "SIGNUP",
  };

  try {
    let res = await mutate({
      endpoint: "SendOTP",
      data: { input: payload },
      service: "AUTH",
    });
    log(res);
    if (res.success) {
      // loading.btnGetstarted = false;
      window.localStorage.setItem("otpReference", res.data.reference);
      //send user to OTP form
      window.localStorage.setItem("display", "otp-verify");
      toast.success(res.message);
    }
  } catch (e) {
    // loading.btnGetstarted = false;
  }
}
// Get rid of the proxy when console.log
function log(arg) {
  console.log(JSON.parse(JSON.stringify(arg)));
}

onMounted(() => {
  const user = window.localStorage.getItem("ezpuser");
  if (user) {
    const obj = JSON.parse(user);
    if (obj && obj.user) {
      args.rememberMe = true;
      userInput.value = obj.user;
      currentInput.value++;
    }
  }
});
</script>

<style scoped>
.head-text {
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #292a29;
}

.sub-head-text {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
</style>
